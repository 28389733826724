
.GreetCard {
    background: #fff;
    position: fixed;
	border-radius: 7px;
    width: 97%;
	z-index: -1;
	top: 70px;
	left: 0px;
	right: 0;
	margin: auto;
    padding: 10px;
    padding-right: 30px;
    box-shadow: 0 15px 30px rgba(0,0,0,0.30), 0 10px 8px rgba(0,0,0,0.22);
    font-weight: bolder;
    font-family: Montserrat;
    font-size: large;
    text-align: right;
}