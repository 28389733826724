.problem .problem-title {
    font-size: 30px;
    font-weight: bolder;
    margin-top: 20px;
    margin-bottom: 30px;
    color: #444444;
}

.problem .problem-description {
    text-align: justify;
    max-width: 800px;
    line-height: 1.5;
    margin-top: 20px;
}

.link-preview {
    border: 1px solid #ccc;
    border-radius: 5px;
    max-width: 700px;
    text-decoration: none;
    margin-top: 20px;
}

.link-preview .image-container {
    height: 134px;
    border-bottom: 1px solid #eee;
    background-size: cover;
    background-position: 50%;
}

.link-preview .title {
    padding: 3px;
    font-weight: bold;
}

.link-preview .title::after {
    content : '\f08e';
    margin-left: 5px;
    font-family: "FontAwesome";
}

.link-preview .link {
    font-size: 12px;
    padding: 3px;
}

.link-preview .link-anchor {
    text-decoration: none;
    color: #666;
}


pre {
    /* white-space: pre-wrap; */
    background: hsl(240, 80%, 95%); 
}