.splash {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0px;
    margin-top: -16px;
}

.CodeWith {
  text-align: left;

}
.content {
	background: #fafafa;
	height: 100vh;
}
.blocks {
	margin-left: 10px;
    display: block;
    padding: 10px;
}
.Leftcontent {
	float: left;
}
.Rightcontent {
	float: right;
    font-family: Lato, Helvetica, sans-serif;
    font-weight: bold;
    top: 100px;
    font-size: -webkit-xxx-large;
    position: relative;
}

.laptop {
	margin-left: 10px;
    display: block;
    max-width: 70%;
    padding: 10px;
}
.main-app {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #f0f3fe;
    left: 0;
    top: 0;
    overflow: auto;
}

.section-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #2f629e;
    text-transform: none;
    font-size: 1.25em;
    font-weight: 600;
    font-family: Lato, "Exo 2", Helvetica, sans-serif;
}

.section-instructor {
    margin-top: 30px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    background: linear-gradient(90deg, #141c3a 20px, transparent 1%) center, linear-gradient(#141c3a 20px, transparent 1%) center,#ffc740;
    background-size: 22px 22px;
}

.section-reviews {
    margin-top: 30px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-flow: column;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-position: right;
}


.section-lb {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-end;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-position: top;
    background-size: 105% 70%;
}
.section-lb1 {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-position: top;
    background-size: 105% 70%;
}

.section-title {
    font-size: 40px;
    font-weight: 900;
    font-family: Montserrat, Lato, sans-serif;
    margin : 10px;
    color: #ffc740;
    background-color: #141c3a;
}

.section-instructor-desc {
    margin : 30px;
    color: #fff;
    max-width: 350px;
    text-align: center;
    background-color: #141c3a;
    
}

.section-instructor-desc h1 {
    font-size: 30px;
    font-weight: 900;
    font-family: Montserrat;
}

.section-instructor-desc p {
    font-size: 20px;
    font-family: Lato;
}

.color-y {
    color: #ffc740;
}

.color-g {
    color: #6df0c2;
}

.quot-name {
    font-size: 15px;
}