 .pathway-parent {
    /* filter: blur(4px);
    -webkit-filter: blur(4px); */
    margin-top: 70px;
 }

 .problem-detail {
   display: flex;
   flex-flow: row wrap;
   padding: 20px;
   justify-content: space-around;
 }

  .cd-container {
    width: 100%;
    max-width: 1080px;
    min-width: 365px;
  }
  
  .cd-container::after {
    content: '';
    display: table;
    clear: both;
  }

  #cd-timeline {
    position: relative;
    padding: 2em 0;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  
  #cd-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 25px;
    height: 100%;
    width: 4px;
    background: #2f629e;
  }
  
  .back-to-pathway {
    color: #2f629e;
    font-size: 18px;
    font-weight: bolder;
    margin: 20px;
    margin-bottom: 0;
  }

  .back-to-pathway::before {
    content: '\f060';
    font-family: "FontAwesome";
    margin-right: 10px;
    font-weight: lighter;
    padding: 5px;
    border: 3px solid #2f629e;
    border-radius: 100%;
  }
  .back-to-pathway:hover {
    cursor: pointer;
  }

  @media only screen and (min-width: 800px) {
    #cd-timeline {
      margin-top: 3em;
      margin-bottom: 3em;
    }
  
    #cd-timeline::before {
      left: 50%;
      margin-left: -2px;
    }
  }
  .cd-timeline-block {
    position: relative;
    margin: 1em 0;
  }
                                                                                                                                                                                                                                                                                     
  .cd-timeline-block:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
  
  @media only screen and (min-width: 800px) {
    .cd-timeline-block {
      margin: 1em 0;
    }
  
    .cd-timeline-block:first-child {
      margin-top: 0;
    }
  
    .cd-timeline-block:last-child {
      margin-bottom: 0;
    }
  }
  .cd-timeline-img {
    position: absolute;
    top: 8px;
    left: 17px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px #2f629e, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
  }
  
  .cd-timeline-img {
    background: #fff;
  }
  
  @media only screen and (min-width: 800px) {
    .cd-timeline-img {
      width: 20px;
      height: 20px;
      left: 50%;
      margin-left: -10px;
      margin-top: 15px;
      /* Force Hardware Acceleration in WebKit */
      -webkit-transform: translateZ(0);
      -webkit-backface-visibility: hidden;
    }
  }
  .cd-timeline-content {
    font-family: Lato, sans-serif;
    position: relative;
    margin-left: 60px;
    margin-right: 30px;
    background:linear-gradient(0deg,#2f629e,#4a90e2);
    border-radius: 4px;
    padding: 1em;
    cursor: pointer;
  }
  .cd-timeline-content .timeline-content-info {
    padding: 5px 10px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 10px;
    border-radius: 2px;
  }
  .cd-timeline-content .timeline-content-info i {
    margin-right: 5px;
  }
  .cd-timeline-content .timeline-content-info .timeline-content-info-title, .cd-timeline-content .timeline-content-info .timeline-content-info-date {
    width: calc(50% - 2px);
    display: inline-block;
  }
  @media (max-width: 500px) {
    .cd-timeline-content .timeline-content-info .timeline-content-info-title, .cd-timeline-content .timeline-content-info .timeline-content-info-date {
      display: block;
      width: 100%;
    }
  }
  .cd-timeline-content .content-skills {
    font-size: 10px;
    padding: 0;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .cd-timeline-content .content-skills li {
    border: 1px solid;
    border-radius: 15px;
    display: inline-block;
    padding: 2px 5px;
    color: rgba(255, 255, 255, 0.7);
    margin: 3px 2px;
    text-align: center;
    flex-grow: 1;
    max-width: 90px;
  }
  
  .content-skills li.h-tag {
      font-weight: bold;
      background: #4a90e2;
  }

  .cd-timeline-content:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .cd-timeline-content h2 {
    color: rgba(255, 255, 255, 1);
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 16px;
  }
  
  .cd-timeline-content p, .cd-timeline-content .cd-date {
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
  }
  
  .cd-timeline-content .cd-date {
    display: inline-block;
  }
  
  .cd-timeline-content p {
    margin: 0.5em;
    line-height: 1.6;
    color: #fafafa;
    text-align: justify;
  }
  
  .cd-timeline-content::before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #4a90e2;
  }
  
  @media only screen and (min-width: 768px) {
    .cd-timeline-content h2 {
      font-size: 16px;
    }
  
    .cd-timeline-content p {
      font-size: 12px;
    }
  
    .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
      font-size: 10px;
    }
  }
  @media only screen and (min-width: 800px) {
    .cd-timeline-content {
      color: white;
      margin-left: 0;
      width: 36%;
      margin: 0 7%;
    }
  
    .cd-timeline-content::before {
      top: 24px;
      left: 100%;
      border-color: transparent;
      border-left-color: #4a90e2;
    }
  
    .cd-timeline-content .cd-date {
      position: absolute;
      width: 100%;
      left: 122%;
      top: 6px;
      font-size: 10px;
    }
  
    .cd-timeline-block:nth-child(even) .cd-timeline-content {
      float: right;
    }
  
    .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
      top: 24px;
      left: auto;
      right: 100%;
      border-color: transparent;
      border-right-color: #4a90e2;
    }
  
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
      float: right;
    }
  
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
      left: auto;
      right: 122%;
      text-align: right;
    }
  }
  
  .loader--hide {
    display: none;
  }