@import url(https://fonts.googleapis.com/css?family=Exo+2:300,400,700);

.profile-card{
  padding:0;
  margin:20px;
  font-size:14px;
  font-family: "Exo 2", sans-serif;
  color: #333;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.pcard{
  position: relative;
  border-radius: 3px;
  width: 300px; /* Remove for full width */
  height: 450px; /* Remove for full width */
  margin:30px;
  box-shadow: 1px 5px 5px rgba(96,39,225,0.20);
  background: #fff;
  animation:fly-in-from-right .5s 1.5s ease both;
}
.pcard-header{
  position: relative;
  height: 220px;
  background-size: cover;
  background-position: top;
  transition: filter .5s ease-in-out;
  -webkit-filter: grayscale(0%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
  filter: grayscale(0%); 
}

.pcard-header:hover {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.pcard-header:after {
    content:'';
  position: absolute;
  width: 100%;
  height: 100%;
    background: transparent;
    filter: blur(0px);
}

.pcard-header:hover:after {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.card-header-bar{
  position: absolute;
  top:0;
  width: 100%;
  z-index: 1;
  width: 100%;
}

.sr-only{
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(0,0,0,0);
  border: none;
  overflow: hidden;
}
.btn-message{
  display: inline-block;
  background: url(https://res.cloudinary.com/dj14cmwoz/image/upload/v1491077483/profile-card/images/message.svg) no-repeat;
  width: 19.37px;
  height: 16.99px;
  margin-right:10px;
  margin-top:10px;
  float: right;
}


svg .polygon{
  fill: #fff;
}
.card-header-slanted-edge{
  position: absolute;
  bottom: -5px;
  z-index: 1;
  width: 100%;
  right:0;
  left:0;
}

.btn-follow{
  position: absolute;
  font-family: "FontAwesome";
  font-size: 1.5em;
  top: -15px;
  right: 25px;
  width: 45px;
  height: 45px;
  background: #fafafa; 
  /* linear-gradient(to left, #2D77C1, #68FAC2); */
  border-radius:100%;
  -webkit-transition: -webkit-box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  -webkit-box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.btn-follow:hover {
  -webkit-box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.15);
}

.btn-follow:after{
    font-family: "FontAwesome";
    content: "\f259";
    color: #222;
    font-weight: normal;
    position: absolute;
    margin-left: -10px;
    margin-top: -10px;
  left: 50%;
  top:50%;
}

.pcard-body{
  text-align:center;
  padding-left: 10px;
}

.name{
    font-size: 20px;
    letter-spacing: 0.1em;
    font-weight: 700;
    font-family: "Exo 2", "Lato", sans-serif;
    text-transform: uppercase;
    margin: 0 auto;
}

.job-title{
    font-size: 12px;
    font-weight: 400;
    font-family: Lato, "Exo 3", sans-serif;
    text-transform: uppercase;
    margin-top: 5px;
    color: #444;
    letter-spacing: 0.05em;
}

.bio{
    font-size: 14px;
    color: #555;
    font-weight: 400;
    margin: 10px auto;
    line-height: 17px;
    padding: 0 4px 0 4px;
    font-family: Lato, Helvetica, sans-serif;
}

.social-accounts {
    padding-top: 20px;
    letter-spacing: 0.5em;
}

.social-accounts img{
  width: 15px;
}

.social-accounts a{
  margin-left: 10px;
  color: #7b7b7b;
}
.social-accounts a:first-child{
  margin-left: 0;
}

.pcard-footer{
  /*position:fixed; /* Full card width/height */
  position: absolute; /* Fixed card width/height */

  left: 0;
  width: 100%;
  bottom: 20px;
}
.stat{
  box-sizing: border-box;
  width: calc(100% / 3);
  float: left;
  text-align: center;
  border-left: 1px solid #EBEBEB;
}
.stat .label{
  display: block;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 11px;
  letter-spacing: 1px;
  color: #222;
}

.stat .value{
  display: block;
  font-weight: 700;
  font-size:17px;
  margin-top: 5px;
}
