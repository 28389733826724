@import url('https://fonts.googleapis.com/css?family=Lato:400,900&display=swap');

.Header {
  text-align: left;
  width: 100vw;
  overflow: hidden;
  padding: 5px;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  display: flex;
  top: 0px;
  left: -5px;
  z-index: 10000;
}

.Header-bg {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.07);
  background: #fff;
}

.header-right-content {
  display: flex;
  flex-flow: row nowrap;
  align-content: flex-end;
  justify-content: flex-end;
  align-items: center;
  margin-right: 25px;
}

.header-left-content {
  display: flex;
  flex-flow: row nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
}

.codewithlogo {
	/* width: 150px; */
	max-height: 50px;
	margin-left: 10px;
  float: left;
}

#button-sign-in {
	float: right;
	margin: -10px;
	border-radius: 7px;
	font-family: monospace;
}

#button-sign-out {
  float: right; 
	text-align: center;
	font-size: x-small;
	max-width: 30%;
	border-radius: 5px;
}


.displayName {
	margin-left: 7px;
	z-index: 10000;
	font-family: monospace;
    font-weight: bold;
    font-size: large;
    color: #141c3a;
}

@media only screen and (max-width: 900px) {
  .displayName {
    display: none;
  }
}

#loggedInUserImage {
	border-radius: 50%;
	max-height: 50px;
	max-width: 50px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.07);
}

.loggedInUserDetails {
  display: flex;
  align-content: center;
}

.btnClrd {
  cursor: pointer;
  margin: 5px;
  border-radius: 3px;
  text-decoration: none;
  padding: 5px;
  transition: .3s;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -o-transition: .3s;
  display: inline-block;
}

.orange {
  color: #555;
}

.orange:hover {
  color: #000;
}

.signoutBtn, .homebutton {
  text-align: center;
  font-family: Lato;
  font-weight: bold;
  font-size: 14px;
  border-left: 1px solid #ddd;
}

.homebutton {
  display: none;
}

.header-left-content .header-title {
  display: none;
}

@media (min-width: 630px) {
  .homebutton {
    display: block;
  }
  .header-left-content .header-title {
    display: block;
    font-size: 25px;
    font-family: Lato, "Exo 2", Helvetica, sans-serif;
    font-weight: 900;
    color: #2f629e;
    margin-left: 10px;
  }
}