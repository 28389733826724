.FormCard {
    background: #fff;
    position: absolute;
	border-radius: 7px;
	z-index: -1;
	top: 150px;
	margin: auto;
    padding: 10px;
    padding-right: 30px;
    box-shadow: 0 15px 30px rgba(0,0,0,0.30), 0 10px 8px rgba(0,0,0,0.22);
    font-weight: bold;
    color: #aaa;
    font-family: Roboto;
    text-align: left;
}

.AddToLB {
    left : 350px;
}

.AddAdmin {
    left: 10px;
}

.FormCardTitle {
    font-size: large;
    font-weight: bolder;
    text-align: left;
    color:#333;
    font-family: Montserrat;
}

.FormCardBody{
    padding: 10px;
}