@import url(https://fonts.googleapis.com/css?family=Lato:400,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Exo+2:300,400,700);
@font-face {
  font-family: 'FontAwesome';
  font-display: swap;
  src: url('#{$fa-font-path}/fontawesome-webfont.eot?v=#{$fa-version}');
  src: url('#{$fa-font-path}/fontawesome-webfont.eot?#iefix&v=#{$fa-version}') format('embedded-opentype'),
    url('#{$fa-font-path}/fontawesome-webfont.woff2?v=#{$fa-version}') format('woff2'),
    url('#{$fa-font-path}/fontawesome-webfont.woff?v=#{$fa-version}') format('woff'),
    url('#{$fa-font-path}/fontawesome-webfont.ttf?v=#{$fa-version}') format('truetype'),
    url('#{$fa-font-path}/fontawesome-webfont.svg?v=#{$fa-version}#fontawesomeregular') format('svg');
    /* src: url('#{$fa-font-path}/FontAwesome.otf') format('opentype'); // used when developing fonts */
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Lato', 'Helvetica', sans-serif;
  touch-action: none; 
}
.loader-container {
  height: 100vh;
  width: 100vw;
  font-family: Helvetica;
  background: #fff;
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
}

.loader--hide {
  display: none;
}


.loader {
  height: 20px;
  width: 250px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.loader--dot {
  -webkit-animation-name: loader;
          animation-name: loader;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  border: 2px solid white;
}
.loader--dot:first-child {
  background-color: #8cc759;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.loader--dot:nth-child(2) {
  background-color: #8c6daf;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.loader--dot:nth-child(3) {
  background-color: #ef5d74;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.loader--dot:nth-child(4) {
  background-color: #f9a74b;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.loader--dot:nth-child(5) {
  background-color: #60beeb;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.loader--dot:nth-child(6) {
  background-color: #fbef5a;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.loader--text {
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  margin: auto;
}
.loader--text:before {
  content: "Heya";
  font-family: Montserrat, "Exo 2";
  font-size: 5em;
  font-weight: bold;
  -webkit-animation-name: loading-text;
          animation-name: loading-text;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes loader {
  15% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  45% {
    -webkit-transform: translateX(230px);
            transform: translateX(230px);
  }
  65% {
    -webkit-transform: translateX(230px);
            transform: translateX(230px);
  }
  95% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes loader {
  15% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  45% {
    -webkit-transform: translateX(230px);
            transform: translateX(230px);
  }
  65% {
    -webkit-transform: translateX(230px);
            transform: translateX(230px);
  }
  95% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes loading-text {
  0% {
    content: "Heya";
  }
  25% {
    content: "Heya.";
  }
  50% {
    content: "Heya..";
  }
  75% {
    content: "Heya...";
  }
}
@keyframes loading-text {
  0% {
    content: "Heya";
  }
  25% {
    content: "Heya.";
  }
  50% {
    content: "Heya..";
  }
  75% {
    content: "Heya...";
  }
}

.splash {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0px;
    margin-top: -16px;
}

.CodeWith {
  text-align: left;

}
.content {
	background: #fafafa;
	height: 100vh;
}
.blocks {
	margin-left: 10px;
    display: block;
    padding: 10px;
}
.Leftcontent {
	float: left;
}
.Rightcontent {
	float: right;
    font-family: Lato, Helvetica, sans-serif;
    font-weight: bold;
    top: 100px;
    font-size: -webkit-xxx-large;
    position: relative;
}

.laptop {
	margin-left: 10px;
    display: block;
    max-width: 70%;
    padding: 10px;
}
.main-app {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #f0f3fe;
    left: 0;
    top: 0;
    overflow: auto;
}

.section-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #2f629e;
    text-transform: none;
    font-size: 1.25em;
    font-weight: 600;
    font-family: Lato, "Exo 2", Helvetica, sans-serif;
}

.section-instructor {
    margin-top: 30px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    background: linear-gradient(90deg, #141c3a 20px, transparent 1%) center, linear-gradient(#141c3a 20px, transparent 1%) center,#ffc740;
    background-size: 22px 22px;
}

.section-reviews {
    margin-top: 30px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-flow: column;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-position: right;
}


.section-lb {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-end;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-position: top;
    background-size: 105% 70%;
}
.section-lb1 {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-position: top;
    background-size: 105% 70%;
}

.section-title {
    font-size: 40px;
    font-weight: 900;
    font-family: Montserrat, Lato, sans-serif;
    margin : 10px;
    color: #ffc740;
    background-color: #141c3a;
}

.section-instructor-desc {
    margin : 30px;
    color: #fff;
    max-width: 350px;
    text-align: center;
    background-color: #141c3a;
    
}

.section-instructor-desc h1 {
    font-size: 30px;
    font-weight: 900;
    font-family: Montserrat;
}

.section-instructor-desc p {
    font-size: 20px;
    font-family: Lato;
}

.color-y {
    color: #ffc740;
}

.color-g {
    color: #6df0c2;
}

.quot-name {
    font-size: 15px;
}
.footer {
    display: flex;
    flex-direction: row wrap;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    justify-items: center;
    width: 100%;
    background-color: #141c30;
    padding-top: 20px;
    padding-bottom: 70px; 
}

.footer-logo {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #fffff0;
}

.footer-title {
    padding-left: 7px;
    text-decoration: none;
    font: Lato, sans-serif;
    font-weight: 700;
    font-size: 20px;
    display: inline;
}

.footer-ref {
    display: flex;
    flex-direction: row;
    color: #fffff0;
    font-size: 12px;
    align-items: center;
}

.f-ref-i {
    padding: 6px;
}

.footer .footer-social-accounts {
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    letter-spacing: 1em;
    flex-direction: row;
    color: #fffff0;
    margin-right: 10px;
}

.footer-social-accounts a {
    color: #fffff0;
}

.f-ref-r {
    display: flex;
    flex-direction: column;
}

@media (min-width: 650px) {
    .footer-title {
        display: inline;
    }
}

@media (min-width: 720px) {
    .f-ref-r{
        flex-direction: row;
    }
}
.Header {
  text-align: left;
  width: 100vw;
  overflow: hidden;
  padding: 5px;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  display: flex;
  top: 0px;
  left: -5px;
  z-index: 10000;
}

.Header-bg {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.07);
  background: #fff;
}

.header-right-content {
  display: flex;
  flex-flow: row nowrap;
  align-content: flex-end;
  justify-content: flex-end;
  align-items: center;
  margin-right: 25px;
}

.header-left-content {
  display: flex;
  flex-flow: row nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
}

.codewithlogo {
	/* width: 150px; */
	max-height: 50px;
	margin-left: 10px;
  float: left;
}

#button-sign-in {
	float: right;
	margin: -10px;
	border-radius: 7px;
	font-family: monospace;
}

#button-sign-out {
  float: right; 
	text-align: center;
	font-size: x-small;
	max-width: 30%;
	border-radius: 5px;
}


.displayName {
	margin-left: 7px;
	z-index: 10000;
	font-family: monospace;
    font-weight: bold;
    font-size: large;
    color: #141c3a;
}

@media only screen and (max-width: 900px) {
  .displayName {
    display: none;
  }
}

#loggedInUserImage {
	border-radius: 50%;
	max-height: 50px;
	max-width: 50px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.07);
}

.loggedInUserDetails {
  display: flex;
  align-content: center;
}

.btnClrd {
  cursor: pointer;
  margin: 5px;
  border-radius: 3px;
  text-decoration: none;
  padding: 5px;
  transition: .3s;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -o-transition: .3s;
  display: inline-block;
}

.orange {
  color: #555;
}

.orange:hover {
  color: #000;
}

.signoutBtn, .homebutton {
  text-align: center;
  font-family: Lato;
  font-weight: bold;
  font-size: 14px;
  border-left: 1px solid #ddd;
}

.homebutton {
  display: none;
}

.header-left-content .header-title {
  display: none;
}

@media (min-width: 630px) {
  .homebutton {
    display: block;
  }
  .header-left-content .header-title {
    display: block;
    font-size: 25px;
    font-family: Lato, "Exo 2", Helvetica, sans-serif;
    font-weight: 900;
    color: #2f629e;
    margin-left: 10px;
  }
}
.profile-card{
  padding:0;
  margin:20px;
  font-size:14px;
  font-family: "Exo 2", sans-serif;
  color: #333;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.pcard{
  position: relative;
  border-radius: 3px;
  width: 300px; /* Remove for full width */
  height: 450px; /* Remove for full width */
  margin:30px;
  box-shadow: 1px 5px 5px rgba(96,39,225,0.20);
  background: #fff;
  -webkit-animation:fly-in-from-right .5s 1.5s ease both;
          animation:fly-in-from-right .5s 1.5s ease both;
}
.pcard-header{
  position: relative;
  height: 220px;
  background-size: cover;
  background-position: top;
  transition: -webkit-filter .5s ease-in-out;
  transition: filter .5s ease-in-out;
  transition: filter .5s ease-in-out, -webkit-filter .5s ease-in-out;
  -webkit-filter: grayscale(0%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
  filter: grayscale(0%); 
}

.pcard-header:hover {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.pcard-header:after {
    content:'';
  position: absolute;
  width: 100%;
  height: 100%;
    background: transparent;
    -webkit-filter: blur(0px);
            filter: blur(0px);
}

.pcard-header:hover:after {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.card-header-bar{
  position: absolute;
  top:0;
  width: 100%;
  z-index: 1;
  width: 100%;
}

.sr-only{
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(0,0,0,0);
  border: none;
  overflow: hidden;
}
.btn-message{
  display: inline-block;
  background: url(https://res.cloudinary.com/dj14cmwoz/image/upload/v1491077483/profile-card/images/message.svg) no-repeat;
  width: 19.37px;
  height: 16.99px;
  margin-right:10px;
  margin-top:10px;
  float: right;
}


svg .polygon{
  fill: #fff;
}
.card-header-slanted-edge{
  position: absolute;
  bottom: -5px;
  z-index: 1;
  width: 100%;
  right:0;
  left:0;
}

.btn-follow{
  position: absolute;
  font-family: "FontAwesome";
  font-size: 1.5em;
  top: -15px;
  right: 25px;
  width: 45px;
  height: 45px;
  background: #fafafa; 
  /* linear-gradient(to left, #2D77C1, #68FAC2); */
  border-radius:100%;
  transition: box-shadow 0.3s ease;
  box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.btn-follow:hover {
  box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.15);
}

.btn-follow:after{
    font-family: "FontAwesome";
    content: "\F259";
    color: #222;
    font-weight: normal;
    position: absolute;
    margin-left: -10px;
    margin-top: -10px;
  left: 50%;
  top:50%;
}

.pcard-body{
  text-align:center;
  padding-left: 10px;
}

.name{
    font-size: 20px;
    letter-spacing: 0.1em;
    font-weight: 700;
    font-family: "Exo 2", "Lato", sans-serif;
    text-transform: uppercase;
    margin: 0 auto;
}

.job-title{
    font-size: 12px;
    font-weight: 400;
    font-family: Lato, "Exo 3", sans-serif;
    text-transform: uppercase;
    margin-top: 5px;
    color: #444;
    letter-spacing: 0.05em;
}

.bio{
    font-size: 14px;
    color: #555;
    font-weight: 400;
    margin: 10px auto;
    line-height: 17px;
    padding: 0 4px 0 4px;
    font-family: Lato, Helvetica, sans-serif;
}

.social-accounts {
    padding-top: 20px;
    letter-spacing: 0.5em;
}

.social-accounts img{
  width: 15px;
}

.social-accounts a{
  margin-left: 10px;
  color: #7b7b7b;
}
.social-accounts a:first-child{
  margin-left: 0;
}

.pcard-footer{
  /*position:fixed; /* Full card width/height */
  position: absolute; /* Fixed card width/height */

  left: 0;
  width: 100%;
  bottom: 20px;
}
.stat{
  box-sizing: border-box;
  width: calc(100% / 3);
  float: left;
  text-align: center;
  border-left: 1px solid #EBEBEB;
}
.stat .label{
  display: block;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 11px;
  letter-spacing: 1px;
  color: #222;
}

.stat .value{
  display: block;
  font-weight: 700;
  font-size:17px;
  margin-top: 5px;
}

#notfound {
    position: relative;
    height: 100vh;
  }
  
  #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  
  .notfound {
    max-width: 520px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
  }
  
  .notfound .notfound-404 {
    position: relative;
    height: 240px;
  }
  
  .notfound .notfound-404 h1 {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 252px;
    font-weight: 900;
    margin: 0px;
    color: #262626;
    text-transform: uppercase;
    letter-spacing: -40px;
    margin-left: -20px;
  }
  
  .notfound .notfound-404 h1>span {
    text-shadow: -8px 0px 0px #fff;
  }
  
  .notfound .notfound-404 h3 {
    font-family: 'Cabin', sans-serif;
    position: relative;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #262626;
    margin: 0px;
    letter-spacing: 3px;
    padding-left: 6px;
  }
  
  .notfound h2 {
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: #000;
    margin-top: 0px;
    margin-bottom: 25px;
  }
  
  @media only screen and (max-width: 767px) {
    .notfound .notfound-404 {
      height: 200px;
    }
    .notfound .notfound-404 h1 {
      font-size: 200px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .notfound .notfound-404 {
      height: 162px;
    }
    .notfound .notfound-404 h1 {
      font-size: 162px;
      height: 150px;
      line-height: 162px;
    }
    .notfound h2 {
      font-size: 16px;
    }
  }
.CodeWith {
  text-align: left;

}
.content {
	background: #fafafa;
	height: 100vh;
}
.blocks {
	margin-left: 10px;
    display: block;
    padding: 10px;
}
.Leftcontent {
	float: left;
}
.Rightcontent {
	float: right;
    font-family: monospace;
    font-weight: bold;
    top: 100px;
    font-size: -webkit-xxx-large;
    position: relative;
}

.laptop {
	margin-left: 10px;
    display: block;
    max-width: 70%;
    padding: 10px;
}
.main-app {
    overflow: auto;
}

.UpdateLB {
    left: 700px;
}

.GreetCard {
    background: #fff;
    position: fixed;
	border-radius: 7px;
    width: 97%;
	z-index: -1;
	top: 70px;
	left: 0px;
	right: 0;
	margin: auto;
    padding: 10px;
    padding-right: 30px;
    box-shadow: 0 15px 30px rgba(0,0,0,0.30), 0 10px 8px rgba(0,0,0,0.22);
    font-weight: bolder;
    font-family: Montserrat;
    font-size: large;
    text-align: right;
}
.FormCard {
    background: #fff;
    position: absolute;
	border-radius: 7px;
	z-index: -1;
	top: 150px;
	margin: auto;
    padding: 10px;
    padding-right: 30px;
    box-shadow: 0 15px 30px rgba(0,0,0,0.30), 0 10px 8px rgba(0,0,0,0.22);
    font-weight: bold;
    color: #aaa;
    font-family: Roboto;
    text-align: left;
}

.AddToLB {
    left : 350px;
}

.AddAdmin {
    left: 10px;
}

.FormCardTitle {
    font-size: large;
    font-weight: bolder;
    text-align: left;
    color:#333;
    font-family: Montserrat;
}

.FormCardBody{
    padding: 10px;
}
.addProblemCard {
    top: 300px;
    left: 10px;
}
.cp-path-main {
    color: #777; 
    overflow-y: auto; 
}
.pway {
    overflow-y: auto; 
}
 .pathway-parent {
    /* filter: blur(4px);
    -webkit-filter: blur(4px); */
    margin-top: 70px;
 }

 .problem-detail {
   display: flex;
   flex-flow: row wrap;
   padding: 20px;
   justify-content: space-around;
 }

  .cd-container {
    width: 100%;
    max-width: 1080px;
    min-width: 365px;
  }
  
  .cd-container::after {
    content: '';
    display: table;
    clear: both;
  }

  #cd-timeline {
    position: relative;
    padding: 2em 0;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  
  #cd-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 25px;
    height: 100%;
    width: 4px;
    background: #2f629e;
  }
  
  .back-to-pathway {
    color: #2f629e;
    font-size: 18px;
    font-weight: bolder;
    margin: 20px;
    margin-bottom: 0;
  }

  .back-to-pathway::before {
    content: '\F060';
    font-family: "FontAwesome";
    margin-right: 10px;
    font-weight: lighter;
    padding: 5px;
    border: 3px solid #2f629e;
    border-radius: 100%;
  }
  .back-to-pathway:hover {
    cursor: pointer;
  }

  @media only screen and (min-width: 800px) {
    #cd-timeline {
      margin-top: 3em;
      margin-bottom: 3em;
    }
  
    #cd-timeline::before {
      left: 50%;
      margin-left: -2px;
    }
  }
  .cd-timeline-block {
    position: relative;
    margin: 1em 0;
  }
                                                                                                                                                                                                                                                                                     
  .cd-timeline-block:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
  
  @media only screen and (min-width: 800px) {
    .cd-timeline-block {
      margin: 1em 0;
    }
  
    .cd-timeline-block:first-child {
      margin-top: 0;
    }
  
    .cd-timeline-block:last-child {
      margin-bottom: 0;
    }
  }
  .cd-timeline-img {
    position: absolute;
    top: 8px;
    left: 17px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px #2f629e, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
  }
  
  .cd-timeline-img {
    background: #fff;
  }
  
  @media only screen and (min-width: 800px) {
    .cd-timeline-img {
      width: 20px;
      height: 20px;
      left: 50%;
      margin-left: -10px;
      margin-top: 15px;
      /* Force Hardware Acceleration in WebKit */
      -webkit-transform: translateZ(0);
      -webkit-backface-visibility: hidden;
    }
  }
  .cd-timeline-content {
    font-family: Lato, sans-serif;
    position: relative;
    margin-left: 60px;
    margin-right: 30px;
    background:linear-gradient(0deg,#2f629e,#4a90e2);
    border-radius: 4px;
    padding: 1em;
    cursor: pointer;
  }
  .cd-timeline-content .timeline-content-info {
    padding: 5px 10px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 10px;
    border-radius: 2px;
  }
  .cd-timeline-content .timeline-content-info i {
    margin-right: 5px;
  }
  .cd-timeline-content .timeline-content-info .timeline-content-info-title, .cd-timeline-content .timeline-content-info .timeline-content-info-date {
    width: calc(50% - 2px);
    display: inline-block;
  }
  @media (max-width: 500px) {
    .cd-timeline-content .timeline-content-info .timeline-content-info-title, .cd-timeline-content .timeline-content-info .timeline-content-info-date {
      display: block;
      width: 100%;
    }
  }
  .cd-timeline-content .content-skills {
    font-size: 10px;
    padding: 0;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .cd-timeline-content .content-skills li {
    border: 1px solid;
    border-radius: 15px;
    display: inline-block;
    padding: 2px 5px;
    color: rgba(255, 255, 255, 0.7);
    margin: 3px 2px;
    text-align: center;
    flex-grow: 1;
    max-width: 90px;
  }
  
  .content-skills li.h-tag {
      font-weight: bold;
      background: #4a90e2;
  }

  .cd-timeline-content:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .cd-timeline-content h2 {
    color: rgba(255, 255, 255, 1);
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 16px;
  }
  
  .cd-timeline-content p, .cd-timeline-content .cd-date {
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
  }
  
  .cd-timeline-content .cd-date {
    display: inline-block;
  }
  
  .cd-timeline-content p {
    margin: 0.5em;
    line-height: 1.6;
    color: #fafafa;
    text-align: justify;
  }
  
  .cd-timeline-content::before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #4a90e2;
  }
  
  @media only screen and (min-width: 768px) {
    .cd-timeline-content h2 {
      font-size: 16px;
    }
  
    .cd-timeline-content p {
      font-size: 12px;
    }
  
    .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
      font-size: 10px;
    }
  }
  @media only screen and (min-width: 800px) {
    .cd-timeline-content {
      color: white;
      margin-left: 0;
      width: 36%;
      margin: 0 7%;
    }
  
    .cd-timeline-content::before {
      top: 24px;
      left: 100%;
      border-color: transparent;
      border-left-color: #4a90e2;
    }
  
    .cd-timeline-content .cd-date {
      position: absolute;
      width: 100%;
      left: 122%;
      top: 6px;
      font-size: 10px;
    }
  
    .cd-timeline-block:nth-child(even) .cd-timeline-content {
      float: right;
    }
  
    .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
      top: 24px;
      left: auto;
      right: 100%;
      border-color: transparent;
      border-right-color: #4a90e2;
    }
  
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
      float: right;
    }
  
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
      left: auto;
      right: 122%;
      text-align: right;
    }
  }
  
  .loader--hide {
    display: none;
  }
.problem-sidebar {
    display: flex;
    flex-flow: column;
    align-content: flex-start;
    justify-content: flex-start;
    max-width: 350px;
    padding: 5px;
    margin: 15px;
    /* border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc; */
}

.problem-option-box {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 14px;
    max-width: 250px;
    padding-bottom: 20px;
    padding-right: 20px;
}

.problem-sidebar .problem-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.problem-sidebar .problem-difficulty, .problem-sidebar .contributor {
    font-size: 14px;
    color: #555;
    padding: 5px;   
}

.problem-sidebar .solved-by {
    font-size: 14px;
    color: #555;
    padding: 5px;   
}
.mark-complete, .mark-favorite {
    margin-top: 30px;
    text-transform: uppercase;
    color: #2f629e;
    cursor: pointer;
}

.mark-complete::before {
    content : '\F058';
    font-family: "FontAwesome";
    margin: 5px; 
}

.mark-favorite::before {
    content : '\F004';
    font-family: "FontAwesome";
    margin: 5px; 
}

.problem-sidebar .problem-tags {
    font-size: 10px;
    padding: 0;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 250px;
}
.problem-sidebar .problem-tags li {
    border: 1px solid;
    border-radius: 15px;
    display: inline-block;
    padding: 2px 5px;
    margin: 3px 2px;
    text-align: center;
    flex-grow: 1;
    max-width: 90px;
}

.problem-sidebar .problem-tags li.h-tag {
    font-weight: bold;
    background: #eee;
}
.problem .problem-title {
    font-size: 30px;
    font-weight: bolder;
    margin-top: 20px;
    margin-bottom: 30px;
    color: #444444;
}

.problem .problem-description {
    text-align: justify;
    max-width: 800px;
    line-height: 1.5;
    margin-top: 20px;
}

.link-preview {
    border: 1px solid #ccc;
    border-radius: 5px;
    max-width: 700px;
    text-decoration: none;
    margin-top: 20px;
}

.link-preview .image-container {
    height: 134px;
    border-bottom: 1px solid #eee;
    background-size: cover;
    background-position: 50%;
}

.link-preview .title {
    padding: 3px;
    font-weight: bold;
}

.link-preview .title::after {
    content : '\F08E';
    margin-left: 5px;
    font-family: "FontAwesome";
}

.link-preview .link {
    font-size: 12px;
    padding: 3px;
}

.link-preview .link-anchor {
    text-decoration: none;
    color: #666;
}


pre {
    /* white-space: pre-wrap; */
    background: hsl(240, 80%, 95%); 
}
.vote {
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: #fbfbfb;
    margin-top: 53px;
}

.CommCard {
  padding: 8px;
  border-bottom: 1px solid #333;
  cursor: pointer;
}

.CommCard:hover {
  background-color: #333;
}

.commCards {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    flex-flow: nowrap;
    overflow: auto;
}

.community-list {
  font-weight: bold;
  font-size: small;
  font-family: 'Lato', 'Helvetica', sans-serif;
  background-color: #000;
  max-width: 250px;
  min-height: 100vh;
}
.VoteCardTitle {
    font-size: 20px;
    font-weight: bolder;
    text-align: left;
    color: #385898;
}

.comm-title {
  font-weight: bold;
    color: #fff;
    font-size: 16px;
    padding: 7px 1px 6px 5px;
    min-width: 250px;
}

.VoteCardBody {
    padding: 5px;
    padding-top: 10px;
    font-weight: bold;
    color: #333;
}
.all-tab-container {
  display: flex;
  flex-flow: nowrap;
  flex-direction: row;
}
.LBContentCard {
    background: #fff;
    border-radius: 3px;
    /* min-width: 300px; */
    box-shadow: 1px 5px 7px rgba(0,0,0,0.15);
    /* padding: 7px; */
    max-width: 335px;
    margin-top: 10px;
    margin-left: 1px;
    margin-bottom: 10px;
    -webkit-animation: fly-in-from-left .5s 1s ease both;
    animation: fly-in-from-left .5s 1s ease both;
}

.right-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
}

.LBContentCard-right {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px rgba(0,0,0,0.12);
    padding: 10px;
    margin: 4px;
    width: -webkit-fill-available;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.member-body {
    font-weight: bold;
    font-size: small;
    font-family: 'Lato', 'Helvetica', sans-serif;
}

.member-body table {
  font-size: 16px;
    white-space: nowrap;
    padding: 7px;
}

.smallInfo {
    float: right;
}

.VoteCardBody .td {
    -webkit-animation:fly-in-from-left .5s 1s ease both;
            animation:fly-in-from-left .5s 1s ease both;
}
.member-picture {
    border-radius: 50%;
    max-height: 37px;
    max-width: 37px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.07);
    margin-right: 10px;
    margin-left: 2px;
}

.comm-detail-body {
    display: flex;
    flex-direction: row;
    padding-top: 7px;
    width: 100%;
    /* align-items: baseline; */
}

.selected-community {
  background-color: #fbfbfb;;
  border-bottom: 1px solid #333;
  color: #111;
  padding: 5px;
}

.comm-title-selected {
  font-size: 20px;
  color: #000;
}
.b-scr {
    min-width: 100px;
}

.option-tags {
    font-size: 10px;
    padding: 0;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.option-tags li {
  border: 1px solid;
  border-radius: 40px;
  display: inline-block;
  padding: 9px 9px;
  margin: 3px 2px;
  text-align: center;
  flex-grow: 1;
  max-width: 300px;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.opt-cancel-btn {
    border: 0;
    background: transparent;
    padding: 0;
    margin-left: 5px;
    color: red;
    cursor: pointer;
    font-weight: bold;
}

.poll-submit-btn {
    padding: 0.5rem 0.5rem;
    border: 0;
    border-radius: 0.317rem;
    color: #fff;
    text-decoration: none;
    font-weight: 700;
    font-family: "Helvetica Neue", Arial, sans-serif;
    cursor: pointer;
    background-color: #1fa3ec;
    margin-top: 10px;
}
.poll-submit-btn:hover {
	opacity: 0.85;
}

.poll-submit-btn:active {
	box-shadow: inset 0 3px 4px hsla(0, 0%, 0%, 0.2);
}

.poll-submit-btn:focus {
	outline: thin dotted #444;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

@media(max-width: 780px) {
    .b-scr {
        display: none;
    }
}

/* chat box */


.d-none {
    display: none !important;
  }
  
  .others-show {
    top: -55px !important;
    z-index: 10 !important;
  }

  .chat-box button {
    border: 0;
    outline: none;
  }
  
  .chat-box img {
    width: 100%;
    object-fit: cover;
  }
  
  .avatar-wrapper {
    border-radius: 50%;
    overflow: hidden;
  }
  
  .chat-box {
    width: 275px;
    /* margin: auto; */
    position: fixed;
    bottom: 0;
    right: 10px;
  }
  .chat-box .chat-header {
    display: flex;
    align-items: center;
    background-color: #385898;
    color: #fff;
    border-radius: 5px 5px 0 0;
    padding: 7px;
    
    letter-spacing: 0.05em;
    font-family: "Exo 2", "Lato", sans-serif;
    text-transform: uppercase;
    margin: 0 auto;
  }
  .chat-box .chat-header .avatar-big {
    width: 35px;
    height: 35px;
  }
  .chat-box .chat-header .name {
    margin-left: 20px;
    font-size: 15px;
    font-weight: 500;
  }
  .chat-box .chat-header .options {
    font-size: 20px;
    color: lightgrey;
    margin-left: auto;
    cursor: pointer;
  }
  .chat-box .chat-room {
    background-color: #f7f9fb;
    padding: 15px;
    min-height: 350px;
    max-height: 350px;
    overflow: auto;
  }
  .chat-box .chat-room .avatar-small {
    width: 25px;
    height: 25px;
  }
  .chat-box .chat-room .message {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
  .chat-box .chat-room .message-left {
    align-items: flex-start;
  }
  .chat-box .chat-room .message-left .bubble {
    border-radius: 0 5px 5px 5px;
  }
  .chat-box .chat-room .message-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .chat-box .chat-room .message-right .bubble {
    border-radius: 5px 0 5px 5px;
  }
  .chat-box .chat-room .bubble {
    padding: 5px;
    font-size: 14px;
    margin-top: 3px;
    display: inline-block;
  }
  .chat-box .chat-room .bubble-light {
    background-color: #1fa3ec;
    color: #fff;
  }
  .chat-box .chat-room .bubble-dark {
    color: #fff;
    background-color: #38566B;
  }
  .chat-box .type-area {
    display: flex;
    height: 50px;
    background-color: #fff;
  }
  .chat-box .type-area .input-wrapper {
    overflow: hidden;
  }
  .chat-box .type-area .input-wrapper input {
    outline: none;
    border: none;
    padding-left: 20px;
    height: 100%;
    width: 220px;
    font-size: 14px;
  }
  .chat-box .type-area .button-add {
    display: flex;
    align-items: center;
    position: relative;
  }
  .chat-box .type-area .button-add i {
    font-size: 30px;
    color: lightgrey;
    cursor: pointer;
  }
  .chat-box .type-area .button-add i:hover {
    color: #be79df;
  }
  .chat-box .type-area .button-add .others {
    display: flex;
    padding: 10px 15px;
    background-color: #fff;
    position: absolute;
    top: 5px;
    z-index: -1;
    right: -52px;
    border-radius: 999px;
    box-shadow: 0 0 25px -5px lightgray;
    transition: 0.3s all ease-out;
  }
  .chat-box .type-area .button-add .others span i {
    font-size: 20px;
    color: #be79df;
  }
  .chat-box .type-area .button-add .others span.image-button {
    margin: 0 25px;
  }
  .chat-box .type-area .button-add .others .emoji-button {
    position: relative;
  }
  .chat-box .type-area .button-add .others .emoji-button .emoji-box {
    display: none;
    position: absolute;
    width: 0px;
    height: 0px;
    top: 0px;
    right: 0px;
    transition: 0.3s all ease-out;
  }
  .chat-box .type-area .button-add .others .emoji-button .emoji-box span {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;
    width: 20px;
    height: 20px;
  }
  .chat-box .type-area .button-send {
    background-color: #fff;
    margin-left: auto;
    width: 65px;
    color: #385898;
    font-weight: bold;
    border-left: 2px solid #f7f9fb;
    border: 0;
  }
  .chat-box .type-area .button-send:hover {
    background-color: #385898;
    color: #fff;
  }
  
  .loader--hide {
    display: none;
  }

  .poll-counter {
    width: 18px;
    height: 18px;
    border-radius: 20px;
    background-color: red;
    color: #fff;
    margin-left: 5px;
    padding: 2px;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    font-size: 11px;
  }

  .poll-selected {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    font-weight: bold;
  }

  .add-option-btn{
    border: 0;
    background: none;
    cursor: pointer;
    border-radius: 20px;
    background-color: whitesmoke;
    padding: 5px;
  }

  .close-sidebar-btn {
    padding: 10px;
    position: fixed;
    top: 60px;
    width: 200px;
    border: 0;
    background-color: transparent;
    font-size: 20px;
    text-align: left;
    font-family: Montserrat;
    font-weight: bold;
  }

  .comm-name-big {
    background-color: #385898;
    border-radius: 3px;
    padding: 20px;
    font-size: 28px;
    font-weight: bold;
    color: #fff;
  }
.Resume {
  text-align: center;
  width: 100%;
  overflow: hidden;
}

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#portraitface {
  position: fixed;
  margin: 0 auto;
  margin-top: 70px;
  -webkit-animation: rotation 2s infinite linear;
  animation: rotation .25s infinite linear alternate;
}

.tiwari {
  position: fixed;
  left: 30%;
  bottom: 223px;
}

#spacey-left {
  display: none;
}
#underdev {
  position: fixed;
  margin: 0 auto;
  left: 35%;
  margin-top: 20px;
  height: 50px;
  z-index: 100;
}

#spacey {
  position: fixed;
  left: 30%;
  bottom: 223px;
}

.chat {
  position: fixed;
  height: 200px;
  display: none;
}

.stars {
  width: 100%;
  height: 100%;
  perspective: 10px;
  -webkit-perspective: 10px;
  -moz-perspective: 10px;
  -ms-perspective: 10px;

  transform-origin: 10% 20%;
  -webkit-transform-origin: 10% 20%;
  -moz-transform-origin: 10% 20%;
  -ms-transform-origin: 10% 20%;
}

.staticstar {
  z-index: -1000;
  position: fixed;
  height: 30px;
}

#portrait-footer {
  opacity: 1;
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 150px;
}

/* Wrapper */
.icon-button {
  background-color: white;
  border-radius: 3.6rem;
  cursor: pointer;
  display: block;
  font-size: 2.0rem;
  height: 3.6rem;
  line-height: 3.6rem;
  margin: 0 5px;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 3.6rem;
}

/* Circle */
.icon-button span {
  border-radius: 0;
  display: block;
  height: 0;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  transition: all 0.3s;
  width: 0;
}
.icon-button:hover span {
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 3.6rem;
  margin: -1.8rem;
}

.facebook span {
  background-color: #3B5998;
}

.github span {
  background-color: #3B5998;
}

.code span {
  background-color: #db5a3c;
}

.linkedin span {
  background-color: #db5a3c;
}

/* Icons */
.icon-button i {
  background: none;
  color: white;
  height: 3.6rem;
  left: 0;
  line-height: 3.6rem;
  position: absolute;
  top: 0;
  transition: all 0.3s;
  width: 3.6rem;
  z-index: 10;
}

.icon-button .icon-facebook {
  color: #000;
}

.icon-button .icon-code {
  color: #000;
}

.icon-button .icon-github {
  color: #000;
}

.icon-button .icon-linkedin {
  color: #000;
}

.icon-button:hover .icon-facebook,

.icon-button:hover .icon-linkedin,

.icon-button:hover .icon-code,

.icon-button:hover .icon-github,

.social {
  margin-top: 0px;
}

.hlayer1 {
  bottom: 0px;
  position: fixed;
  z-index: -100;
  height: 100%;
  width: 20000px;
  background: #060b14;

}

.hlayer2 {
  bottom: 20px;
  position: fixed;
}

.hlayer3 {
  bottom: 0;
  width: 20000px;
  height: 100%;

  background-position: bottom center;
  background-size: auto;
  background-repeat: repeat-x;
  position: fixed;
  z-index: 1;
}

.hlayer7 {
  bottom: 200px;
  width: 500px;
  height: 4000px;
  background-position: bottom center;
  background-size: auto;
  background-repeat: repeat-y;
  position: fixed;
  z-index: 0;
}

.hlayer8 {
  bottom: 0px;
  width: 500px;
  height: 4000px;
  background-position: bottom center;
  background-size: auto;
  background-repeat: repeat-y;
  position: fixed;
   z-index: 5; 
}
.hlayer9{
  bottom: 0px;
  width: 458px;
  height: 652px;
  background-position: bottom center;
  background-size: contain;
  background-repeat: no-repeat;
  position: fixed;
  z-index: 5;
}
.hlayer4 {
  position: fixed;
  bottom: 0;
  z-index: 0;
}

.hlayer5 {
  bottom: 150px;
  overflow: hidden;
  background-position: bottom center;
  background-size: auto;
  background-repeat: no-repeat;
  position: fixed;
  z-index: -2;
}

.hlayer6 {
  bottom: 200px;
  position: fixed;
  z-index: -1;
}
.control-text{
    position: absolute;
    z-index: 20000;
    bottom: 14px;
    left: 35%;
    font-family: 'Fredoka One', cursive;
    color: #fff;
    font-size: 30px;
    -webkit-animation: blinker 1s linear infinite;
            animation: blinker 1s linear infinite;
}

@-webkit-keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@-webkit-keyframes moveX{
  from{background-position-x:0px;}
  to{background-position-x:-1818px;}
}

@keyframes moveX{
  from{background-position-x:0px;}
  to{background-position-x:-1818px;}
}

.education {
  height: 30px;
  width: 800px;
  /* background-color: #ee4540; */
  background-color: #394551;
  border-radius: 1px;
  border: #333 12px solid;
  color: #fffafa;
  text-align: left;
  padding: 20px;
  font-family: Montserrat, sans-serif;
}

.scroll-anchor {
  background-color: #222;
  border: #333 25px solid;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  margin-left: 350px;
}

.scroll-rope {
  width: 80px;
  height: 2500px;
  margin-left: 360px;
  border: #333 5px solid;
  background-color:transparent;
}
.scroll-rope2 {
  width: 10px;
  height: 50px;
  margin-left: 395px;
  /* border: #333 5px solid; */
  background-color:#333;
}
