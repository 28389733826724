.problem-sidebar {
    display: flex;
    flex-flow: column;
    align-content: flex-start;
    justify-content: flex-start;
    max-width: 350px;
    padding: 5px;
    margin: 15px;
    /* border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc; */
}

.problem-option-box {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 14px;
    max-width: 250px;
    padding-bottom: 20px;
    padding-right: 20px;
}

.problem-sidebar .problem-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.problem-sidebar .problem-difficulty, .problem-sidebar .contributor {
    font-size: 14px;
    color: #555;
    padding: 5px;   
}

.problem-sidebar .solved-by {
    font-size: 14px;
    color: #555;
    padding: 5px;   
}
.mark-complete, .mark-favorite {
    margin-top: 30px;
    text-transform: uppercase;
    color: #2f629e;
    cursor: pointer;
}

.mark-complete::before {
    content : '\f058';
    font-family: "FontAwesome";
    margin: 5px; 
}

.mark-favorite::before {
    content : '\f004';
    font-family: "FontAwesome";
    margin: 5px; 
}

.problem-sidebar .problem-tags {
    font-size: 10px;
    padding: 0;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 250px;
}
.problem-sidebar .problem-tags li {
    border: 1px solid;
    border-radius: 15px;
    display: inline-block;
    padding: 2px 5px;
    margin: 3px 2px;
    text-align: center;
    flex-grow: 1;
    max-width: 90px;
}

.problem-sidebar .problem-tags li.h-tag {
    font-weight: bold;
    background: #eee;
}