.footer {
    display: flex;
    flex-direction: row wrap;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    justify-items: center;
    width: 100%;
    background-color: #141c30;
    padding-top: 20px;
    padding-bottom: 70px; 
}

.footer-logo {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #fffff0;
}

.footer-title {
    padding-left: 7px;
    text-decoration: none;
    font: Lato, sans-serif;
    font-weight: 700;
    font-size: 20px;
    display: inline;
}

.footer-ref {
    display: flex;
    flex-direction: row;
    color: #fffff0;
    font-size: 12px;
    align-items: center;
}

.f-ref-i {
    padding: 6px;
}

.footer .footer-social-accounts {
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    letter-spacing: 1em;
    flex-direction: row;
    color: #fffff0;
    margin-right: 10px;
}

.footer-social-accounts a {
    color: #fffff0;
}

.f-ref-r {
    display: flex;
    flex-direction: column;
}

@media (min-width: 650px) {
    .footer-title {
        display: inline;
    }
}

@media (min-width: 720px) {
    .f-ref-r{
        flex-direction: row;
    }
}