.vote {
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: #fbfbfb;
    margin-top: 53px;
}

.CommCard {
  padding: 8px;
  border-bottom: 1px solid #333;
  cursor: pointer;
}

.CommCard:hover {
  background-color: #333;
}

.commCards {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    flex-flow: nowrap;
    overflow: auto;
}

.community-list {
  font-weight: bold;
  font-size: small;
  font-family: 'Lato', 'Helvetica', sans-serif;
  background-color: #000;
  max-width: 250px;
  min-height: 100vh;
}
.VoteCardTitle {
    font-size: 20px;
    font-weight: bolder;
    text-align: left;
    color: #385898;
}

.comm-title {
  font-weight: bold;
    color: #fff;
    font-size: 16px;
    padding: 7px 1px 6px 5px;
    min-width: 250px;
}

.VoteCardBody {
    padding: 5px;
    padding-top: 10px;
    font-weight: bold;
    color: #333;
}
.all-tab-container {
  display: flex;
  flex-flow: nowrap;
  flex-direction: row;
}
.LBContentCard {
    background: #fff;
    border-radius: 3px;
    /* min-width: 300px; */
    box-shadow: 1px 5px 7px rgba(0,0,0,0.15);
    /* padding: 7px; */
    max-width: 335px;
    margin-top: 10px;
    margin-left: 1px;
    margin-bottom: 10px;
    -webkit-animation: fly-in-from-left .5s 1s ease both;
    animation: fly-in-from-left .5s 1s ease both;
}

.right-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
}

.LBContentCard-right {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px rgba(0,0,0,0.12);
    padding: 10px;
    margin: 4px;
    width: -webkit-fill-available;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.member-body {
    font-weight: bold;
    font-size: small;
    font-family: 'Lato', 'Helvetica', sans-serif;
}

.member-body table {
  font-size: 16px;
    white-space: nowrap;
    padding: 7px;
}

.smallInfo {
    float: right;
}

.VoteCardBody .td {
    animation:fly-in-from-left .5s 1s ease both;
}
.member-picture {
    border-radius: 50%;
    max-height: 37px;
    max-width: 37px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.07);
    margin-right: 10px;
    margin-left: 2px;
}

.comm-detail-body {
    display: flex;
    flex-direction: row;
    padding-top: 7px;
    width: 100%;
    /* align-items: baseline; */
}

.selected-community {
  background-color: #fbfbfb;;
  border-bottom: 1px solid #333;
  color: #111;
  padding: 5px;
}

.comm-title-selected {
  font-size: 20px;
  color: #000;
}
.b-scr {
    min-width: 100px;
}

.option-tags {
    font-size: 10px;
    padding: 0;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.option-tags li {
  border: 1px solid;
  border-radius: 40px;
  display: inline-block;
  padding: 9px 9px;
  margin: 3px 2px;
  text-align: center;
  flex-grow: 1;
  max-width: 300px;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.opt-cancel-btn {
    border: 0;
    background: transparent;
    padding: 0;
    margin-left: 5px;
    color: red;
    cursor: pointer;
    font-weight: bold;
}

.poll-submit-btn {
    padding: 0.5rem 0.5rem;
    border: 0;
    border-radius: 0.317rem;
    color: #fff;
    text-decoration: none;
    font-weight: 700;
    font-family: "Helvetica Neue", Arial, sans-serif;
    cursor: pointer;
    background-color: #1fa3ec;
    margin-top: 10px;
}
.poll-submit-btn:hover {
	opacity: 0.85;
}

.poll-submit-btn:active {
	box-shadow: inset 0 3px 4px hsla(0, 0%, 0%, 0.2);
}

.poll-submit-btn:focus {
	outline: thin dotted #444;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

@media(max-width: 780px) {
    .b-scr {
        display: none;
    }
}

/* chat box */


.d-none {
    display: none !important;
  }
  
  .others-show {
    top: -55px !important;
    z-index: 10 !important;
  }

  .chat-box button {
    border: 0;
    outline: none;
  }
  
  .chat-box img {
    width: 100%;
    object-fit: cover;
  }
  
  .avatar-wrapper {
    border-radius: 50%;
    overflow: hidden;
  }
  
  .chat-box {
    width: 275px;
    /* margin: auto; */
    position: fixed;
    bottom: 0;
    right: 10px;
  }
  .chat-box .chat-header {
    display: flex;
    align-items: center;
    background-color: #385898;
    color: #fff;
    border-radius: 5px 5px 0 0;
    padding: 7px;
    
    letter-spacing: 0.05em;
    font-family: "Exo 2", "Lato", sans-serif;
    text-transform: uppercase;
    margin: 0 auto;
  }
  .chat-box .chat-header .avatar-big {
    width: 35px;
    height: 35px;
  }
  .chat-box .chat-header .name {
    margin-left: 20px;
    font-size: 15px;
    font-weight: 500;
  }
  .chat-box .chat-header .options {
    font-size: 20px;
    color: lightgrey;
    margin-left: auto;
    cursor: pointer;
  }
  .chat-box .chat-room {
    background-color: #f7f9fb;
    padding: 15px;
    min-height: 350px;
    max-height: 350px;
    overflow: auto;
  }
  .chat-box .chat-room .avatar-small {
    width: 25px;
    height: 25px;
  }
  .chat-box .chat-room .message {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
  .chat-box .chat-room .message-left {
    align-items: flex-start;
  }
  .chat-box .chat-room .message-left .bubble {
    border-radius: 0 5px 5px 5px;
  }
  .chat-box .chat-room .message-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .chat-box .chat-room .message-right .bubble {
    border-radius: 5px 0 5px 5px;
  }
  .chat-box .chat-room .bubble {
    padding: 5px;
    font-size: 14px;
    margin-top: 3px;
    display: inline-block;
  }
  .chat-box .chat-room .bubble-light {
    background-color: #1fa3ec;
    color: #fff;
  }
  .chat-box .chat-room .bubble-dark {
    color: #fff;
    background-color: #38566B;
  }
  .chat-box .type-area {
    display: flex;
    height: 50px;
    background-color: #fff;
  }
  .chat-box .type-area .input-wrapper {
    overflow: hidden;
  }
  .chat-box .type-area .input-wrapper input {
    outline: none;
    border: none;
    padding-left: 20px;
    height: 100%;
    width: 220px;
    font-size: 14px;
  }
  .chat-box .type-area .button-add {
    display: flex;
    align-items: center;
    position: relative;
  }
  .chat-box .type-area .button-add i {
    font-size: 30px;
    color: lightgrey;
    cursor: pointer;
  }
  .chat-box .type-area .button-add i:hover {
    color: #be79df;
  }
  .chat-box .type-area .button-add .others {
    display: flex;
    padding: 10px 15px;
    background-color: #fff;
    position: absolute;
    top: 5px;
    z-index: -1;
    right: -52px;
    border-radius: 999px;
    box-shadow: 0 0 25px -5px lightgray;
    transition: 0.3s all ease-out;
  }
  .chat-box .type-area .button-add .others span i {
    font-size: 20px;
    color: #be79df;
  }
  .chat-box .type-area .button-add .others span.image-button {
    margin: 0 25px;
  }
  .chat-box .type-area .button-add .others .emoji-button {
    position: relative;
  }
  .chat-box .type-area .button-add .others .emoji-button .emoji-box {
    display: none;
    position: absolute;
    width: 0px;
    height: 0px;
    top: 0px;
    right: 0px;
    transition: 0.3s all ease-out;
  }
  .chat-box .type-area .button-add .others .emoji-button .emoji-box span {
    user-select: none;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;
    width: 20px;
    height: 20px;
  }
  .chat-box .type-area .button-send {
    background-color: #fff;
    margin-left: auto;
    width: 65px;
    color: #385898;
    font-weight: bold;
    border-left: 2px solid #f7f9fb;
    border: 0;
  }
  .chat-box .type-area .button-send:hover {
    background-color: #385898;
    color: #fff;
  }
  
  .loader--hide {
    display: none;
  }

  .poll-counter {
    width: 18px;
    height: 18px;
    border-radius: 20px;
    background-color: red;
    color: #fff;
    margin-left: 5px;
    padding: 2px;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    font-size: 11px;
  }

  .poll-selected {
    transform: scale(1.1);
    font-weight: bold;
  }

  .add-option-btn{
    border: 0;
    background: none;
    cursor: pointer;
    border-radius: 20px;
    background-color: whitesmoke;
    padding: 5px;
  }

  .close-sidebar-btn {
    padding: 10px;
    position: fixed;
    top: 60px;
    width: 200px;
    border: 0;
    background-color: transparent;
    font-size: 20px;
    text-align: left;
    font-family: Montserrat;
    font-weight: bold;
  }

  .comm-name-big {
    background-color: #385898;
    border-radius: 3px;
    padding: 20px;
    font-size: 28px;
    font-weight: bold;
    color: #fff;
  }